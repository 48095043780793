const Contact = () => {
  return (
    <div className="max-w-4xl flex flex-col items-center mx-auto mb-80 px-8">
      <h2 className="text-5xl mb-10 text-center">
        Here's how you can reach us:
      </h2>
      <div className="wrapper text-xl text-center">
        <div className="mb-3">
          <a
            href="https://goo.gl/maps/8AYpPCjSTiwmba4EA"
            target="_blank"
            rel="noreferrer"
          >
            <span className="opacity-60">Primary office location -</span> Business
            Center 1, M Floor, The Meydan Hotel, Nad Al Sheba, Dubai, U.A.E.
          </a>
        </div>

        <div>
          <a className="link" href="mailto: hello@whale.capital">
            <span className="link-description opacity-60">Email -</span>{" "}
            hello@whale.capital
          </a>
        </div>
      </div>
    </div>
  );
};

export default Contact;
